import './CreateOrEditPromptPage.scss';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';
import { Prompt } from '../SettingsConstants';
import { postPreviewPrompt, postSaveOrUpdatePrompt } from '../../../apis/SettingsApi';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import CreateOrEditPromptForm from '../forms/CreateOrEditPromptForm';
import { User } from '../../../types/UserTypes';
import SubtleButton from '../../common/buttons/SubtleButton';

const EMPTY_PROMPT: Prompt = {
  _id: null,
  name: '',
  message: '',
  sections: [],
};

function CreateOrEditPromptPage() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isEditingExistingPrompt = state && state.prompt && state.prompt._id;
  const [loadingPreviews, setLoadingPreviews] = useState(false);

  const initialPrompt = state && state.prompt ? state.prompt : EMPTY_PROMPT;
  const [prompt, setPrompt] = useState<Prompt>(initialPrompt);

  const createOrEditPromptTitle = isEditingExistingPrompt
    ? 'Modify prompt'
    : 'Build new prompt';
  const createOrEditPromptSubtitle = isEditingExistingPrompt
    ? 'Modify your message prompt to deliver unique, personalised messages'
    : 'Create your message prompt to deliver unique, personalised messages';
  const createOrEditPromptButtonText = isEditingExistingPrompt
    ? 'Save'
    : 'Create';

  const back = () => {
    navigate('/settings/prompts');
  }

  const savePrompt = async () => {
    console.log(prompt);
    const response = await postSaveOrUpdatePrompt(prompt);
    console.log(response);
    setUser({ ...user as User, prompts: response.prompts });
  };

  const savePromptAndReturn = async () => {
    await savePrompt();
    back();
  }

  const previewPrompt = async () => {
    console.log(prompt);
    await savePrompt();
    setLoadingPreviews(true);
    const response = await postPreviewPrompt(prompt);
    setLoadingPreviews(false);
    console.log(response);
    const existingPromptIds = user?.prompts.map(prompt => prompt._id);
    const updatedPrompt = isEditingExistingPrompt
      ? response.find(previewPrompt => previewPrompt._id === prompt._id)
      : response.filter(previewPrompt => existingPromptIds?.includes(previewPrompt._id) )
    setUser({ ...user as User, prompts: response });
    navigate('/settings/prompts/previews', { state: { prompt: updatedPrompt }});
  };

  const disableSavePromptButton = !prompt || (prompt && prompt.sections.length === 0);

  return (
    <article className={'create-edit-prompts-page'}>
      <section className={'create-edit-prompts-content'}>
        <header className={'create-edit-prompts-page-header'}>
          <h2 className={'title'}>{createOrEditPromptTitle}</h2>
          <h3 className={'subtitle'}>
            {createOrEditPromptSubtitle}
          </h3>
        </header>
        <section className={'create-edit-prompts-page-form'}>
          <CreateOrEditPromptForm isEditingExistingPrompt={isEditingExistingPrompt} prompt={prompt} setPrompt={setPrompt} />
        </section>
      </section>
      <footer className={'create-edit-prompts-footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <SubtleButton content={createOrEditPromptButtonText} onClick={savePromptAndReturn} disabled={disableSavePromptButton} />
          <PrimaryButton content={'Preview'} onClick={previewPrompt} disabled={disableSavePromptButton} loading={loadingPreviews} />
        </section>
        <section className={'footer-right'}>
          {'1/2 steps'}
        </section>
      </footer>
    </article>
  );
}

export default CreateOrEditPromptPage;