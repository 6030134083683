import './ReorderSection.scss';

import GreyUpArrow from '../../../../assets/icons/grey-up-arrow.svg'
import GreyDownArrow from '../../../../assets/icons/grey-down-arrow.svg'
import PurpleUpArrow from '../../../../assets/icons/hover-up-arrow.svg'
import PurpleDownArrow from '../../../../assets/icons/hover-down-arrow.svg'

import HoverIconButton from '../../../common/buttons/composite/HoverIconButton';

interface ReorderSectionProps {
  moveSectionUp: (moveUp: boolean) => () => void
}

function ReorderSection({ moveSectionUp } : ReorderSectionProps) {
  return (
    <section className={'reorder-prompt-section'}>
      <HoverIconButton icon={GreyUpArrow} hoveredIcon={PurpleUpArrow} onClick={moveSectionUp(true)} />
      <HoverIconButton icon={GreyDownArrow} hoveredIcon={PurpleDownArrow} onClick={moveSectionUp(false)} />
    </section>
  )
}

export default ReorderSection;