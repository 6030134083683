import './PersonalisedSection.scss';

import { useState } from 'react';

import { CustomVariableToDisplayTextMap, PersonalizationVariable, PersonalizationVariableOptions, PromptPersonalisationVariableOptions } from '../../../engagements/helpers/AIPromptHelpers';

import FormItem from '../../../engagements/forms/FormItem';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineTag from '../../../common/tags/OutlineTag';
import CrossIconButton from '../../../common/buttons/composite/CrossIconButton';
import MultiselectDropdown from '../../../common/dropdowns/MultiselectDropdown';
import { PromptSectionProps } from '../../SettingsConstants';

const PERSONALISED_SECTION_TEXT_CAPTION = 'Give us an example of the type of message you want here.';

function PersonalisedSection({ section, setSection }: PromptSectionProps<string | string[] | PersonalizationVariable[]> ) {
  const { sectionText, variables } = section;
  const [selectedVariables, setSelectedVariables] = useState<string[]>(variables ? variables : []);

  const setVariables = (variables: string[]) => {
    setSelectedVariables(variables);
    setSection('variables')(variables);
  };

  const deletePersonalizationVariable = (variable: string) => () => {
    setSelectedVariables(selectedVariables.filter((selectedVariable) => selectedVariable !== variable));
  };

  console.log(CustomVariableToDisplayTextMap);
  console.log(PromptPersonalisationVariableOptions);

  const tags = selectedVariables.map((variable) => <OutlineTag key={variable} content={<>{variable}<CrossIconButton onClick={deletePersonalizationVariable(variable)} /></>} />);
  return (
    <section className={'personalised-section'}>
      <FormItem label={'Section text'}>
        <TextAreaInput text={sectionText} setText={setSection('sectionText')} placeholder={'Type your example message here'} />
        <span className={'personalised-section-caption'}>{PERSONALISED_SECTION_TEXT_CAPTION}</span>
      </FormItem>
      <FormItem label={'Add personalisation'} additionalClassNames={['personalisation-dropdown']}>
        <MultiselectDropdown defaultDisplayText={'Select variables'} options={PromptPersonalisationVariableOptions} select={setVariables} selected={selectedVariables} />
        {tags}
      </FormItem>
    </section>
  )
};

export default PersonalisedSection;