import { ReactNode } from 'react';
import Dropdown from '../../../common/dropdowns/Dropdown';
import { PromptSection, PromptType } from '../../SettingsConstants';
import './PromptSectionManager.scss';
import StaticSection from './StaticSection';
import OfferSection from './OfferSection';
import PersonalisedSection from './PersonalisedSection';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import { PersonalizationVariable } from '../../../engagements/helpers/AIPromptHelpers';
import ReorderSection from './ReorderSection';

const { PersonalisedMessage, StaticMessage, Offer } = PromptType;

const PromptTypeOptions = [
  { displayText: PersonalisedMessage, value: PersonalisedMessage },
  { displayText: StaticMessage, value: StaticMessage },
  { displayText: Offer, value: Offer },
];

function getSectionTypeComponent(type: PromptType, section: PromptSection, setSection: (fieldName: string) => (fieldValue: string | string[] | PersonalizationVariable[]) => void ): ReactNode {
  switch (type) {
    case PersonalisedMessage:
      return <PersonalisedSection section={section} setSection={setSection}/>;
    case StaticMessage:
      return <StaticSection section={section} setSection={setSection} />;
    case Offer:
      return <OfferSection section={section} setSection={setSection} />;
    default:
      return null;
  }
};

interface PromptSectionManagerProps {
  section: any,
  setSection: (section: PromptSection) => void,
  deleteSection: () => void,
  moveSectionUp: (moveUp: boolean) => () => void
}

function PromptSectionManager({ section, setSection, deleteSection, moveSectionUp } : PromptSectionManagerProps) {
  const { order, type } = section;
  const sectionHeading = `Section ${order}`;
  const setSectionType = (sectionType: PromptType) => setSection({ ...section, type: sectionType });
  const setSectionField = (fieldName: string) => (fieldValue: string | string[] | PersonalizationVariable[]) => setSection({ ...section, [fieldName]: fieldValue });

  const sectionTypeComponent = getSectionTypeComponent(type, section, setSectionField);

  return (
    <section className={'prompt-section-manager'}>
      <h3 className={'section-heading'}>{sectionHeading}</h3>
      <section className={'section-content'}>
        <Dropdown options={PromptTypeOptions} select={setSectionType} selected={type as PromptType} defaultDisplayText={'Section type'} />
        {sectionTypeComponent}
        <ReorderSection moveSectionUp={moveSectionUp} />
      </section>
      <TrashIconButton additionalClassNames={['delete-prompt-section']} onClick={deleteSection} />
    </section>
  )
};

export default PromptSectionManager;