import './WorkRexPage.scss';

import { Outlet } from 'react-router';

import NavigationSidebar from '../navigation/sidebar/NavigationSidebar';

function WorkRexPage() {
  return (
    <main className={'work-rex-page'}>
      <NavigationSidebar />
      <Outlet />
    </main>
  )
}

export default WorkRexPage;