import './PreviewHeader.scss';

import React from 'react';

interface PreviewHeaderProps {
  name: string,
  target: string,
  schedule: string,
  messageNumber: number,
  totalMessages: number,
};

function PreviewHeader({ name, target, schedule, messageNumber, totalMessages } : Readonly<PreviewHeaderProps>) {
  return (
    <header className={'preview-header'}>
      <span className={'preview-header-label'}>
        {'Send to'}
      </span>
      <span className={'preview-name'}>{name}</span>
      <span className={'preview-target'}>{target}</span>
      {schedule && <span className={'preview-schedule'}>{`Scheduled send: ${schedule}`}</span>}
      <span className={'preview-number'}>{`Showing (${messageNumber}/${totalMessages})`}</span>
    </header>
  );
}

export default PreviewHeader;