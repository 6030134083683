import './OfferSection.scss';

import useAuth from '../../../../context/AuthContext';
import { PromptSectionProps } from '../../SettingsConstants';

import Dropdown from '../../../common/dropdowns/Dropdown';
import FormItem from '../../../engagements/forms/FormItem';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import { useState } from 'react';
import TextViewerModal from '../../../common/modals/TextViewerModal';

const OFFER_SECTION_TEXT_CAPTION = 'Give us an example of the type of message you want here.';

function OfferSection({ section, setSection }: PromptSectionProps<string>) {
  const [textToDisplay, setTextToDisplay] = useState<string | null>(null);
  const displayText = (text: string) => () => setTextToDisplay(text);
  const { user } = useAuth();
  const offers = user && user.offers
    ? user.offers
        .filter(offer => offer.baseOffer)
        .map(offer => ({ displayText: offer.name, value: offer._id }))
    : [];
  const { sectionText, offer } = section;
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);

  const foundOffer = user && user.offers ? user.offers.find(existingOffers => existingOffers._id === offer) : '';

  const offerRules = foundOffer ? foundOffer.rules : '';
  const offerRulesSubstring = offerRules && offerRules.length > 50 ? `${offerRules.substring(0, 50)}...` : offerRules;
  return (
    <section className={'offer-section'}>
      { showingText && <TextViewerModal text={textToDisplay} close={closeTextToDisplayModal} /> }
      <FormItem label={'Section text'}>
        <TextAreaInput text={sectionText} setText={setSection('sectionText')} placeholder={'Type your example message here'} />
        <span className={'offer-section-caption'}>{OFFER_SECTION_TEXT_CAPTION}</span>
      </FormItem>
      <FormItem label={'Add offer'}>
        <Dropdown options={offers} defaultDisplayText={'Select an offer'} selected={offer} select={setSection('offer')} />
      </FormItem>
      {foundOffer && (
        <FormItem label={'Offer terms and conditions'} additionalClassNames={['offer-terms']}>
          <span className={'offer-terms-text'}>{offerRulesSubstring}</span>
          <OutlineButton content={'See full terms and conditions'} onClick={displayText(offerRules)} />
        </FormItem>
        )
      }
    </section>
  )
};

export default OfferSection;