
interface CustomVariableMap {
  [key: string]: string
}

interface CustomVariablesMap {
  [key: string]: CustomVariableMap
};

export enum PersonalizationVariable {
  CustomerSegment = 'Customer segment',
  PurchaseHistory = 'Purchase history',
};

export const PersonalizationVariableOptions = [
  { displayText: PersonalizationVariable.CustomerSegment, value: PersonalizationVariable.CustomerSegment },
  { displayText: PersonalizationVariable.PurchaseHistory, value: PersonalizationVariable.PurchaseHistory },
]

export const PromptPersonalisationVariableOptions = [
  ...PersonalizationVariableOptions,
  { displayText: 'User First Name', value: 'UserFirstName' },
  { displayText: 'User Last name', value: 'UserLastName' },
  { displayText: 'User Full Name', value: 'UserFullName' },
  { displayText: 'User Phone Number', value: 'UserPhoneNumber' },
  { displayText: 'User Email Address', value: 'UserEmailAddress' },
  { displayText: 'Sender Name', value: 'SenderName' },
  { displayText: 'Customer First Name', value: 'customerfirstname' },
  { displayText: 'Customer Last Name', value: 'customerlastname' },
  { displayText: 'Customer Full Name', value: 'customerfullname' },
  { displayText: 'Customer Phone Number', value: 'customerphonenumber' },
  { displayText: 'Customer Email Address', value: 'customeremailaddress' },
  { displayText: 'Status', value: 'status' },
  { displayText: 'Client Id', value: 'clientid' },
  { displayText: 'Last Interaction Date', value: 'lastinteractiondate' },
  { displayText: 'Next Interaction Date', value: 'nextinteractiondate' },
  { displayText: 'First Interaction Date', value: 'firstinteractiondate' },
  { displayText: 'Last Interaction Date', value: 'lastappointmentdate' },
  { displayText: 'Next Appointment Date', value: 'nextappointmentdate' },
  { displayText: 'First Appointment Date', value: 'firstappointmentdate' },
  { displayText: 'First Transaction Date', value: 'firsttransactiondate' },
  { displayText: 'Last Transaction Date', value: 'lasttransactiondate' },
  { displayText: 'Next Transaction Date', value: 'nexttransactiondate' },
  { displayText: 'Count Interactions', value: 'countinteractions' },
  { displayText: 'Count Appointments', value: 'countappointments' },
  { displayText: 'Count Transactions', value: 'counttransactions' },
  { displayText: 'Lifetime Value', value: 'lifetimevalue' },
  { displayText: 'Average Transaction Value', value: 'averagetransactionvalue' },
  { displayText: 'Average Purchase Frequency', value: 'averagepurchasefrequency' },
  { displayText: 'Last Interaction Details', value: 'lastinteractiondetails' },
  { displayText: 'Last Interaction Timing Details', value: 'lastinteractiontimingdetails' },
  { displayText: 'Company Name', value: 'companyname' },
  { displayText: 'Company Description', value: 'companydescription' },
]

const CustomVariable: CustomVariablesMap = {
  User: {
    userfirstname: 'UserFirstName',
    userlastname: 'UserLastName',
    userfullname: 'UserFullName',
    userphonenumber: 'UserPhoneNumber',
    useremailaddress: 'UserEmailAddress',
    sendername: 'SenderName',
  },
  Recipient: {
    customerfirstname: 'customerfirstname',
    customerlastname: 'customerlastname',
    customerfullname: 'customerfullname',
    customerphonenumber: 'customerphonenumber',
    customeremailaddress: 'customeremailaddress',
    // TODO: Add in - address: 'address',
    // TODO: Add in - birthdate: 'birthdate',
    // TODO: Add in - age: 'age',
    // TODO: Add in - groupname: 'groupname',
    // TODO: Ask lance - source: 'source',
    status: 'status',
    clientid: 'clientid',
    // TODO: Add in - preference: 'preference',
  },
  Interaction: {
    lastinteractiondate: 'lastinteractiondate',
    nextinteractiondate: 'nextinteractiondate',
    firstinteractiondate: 'firstinteractiondate',
    lastappointmentdate: 'lastappointmentdate',
    nextappointmentdate: 'nextappointmentdate',
    firstappointmentdate: 'firstappointmentdate',
    firsttransactiondate: 'firsttransactiondate',
    lasttransactiondate: 'lasttransactiondate',
    nexttransactiondate: 'nexttransactiondate',
    countinteractions: 'countinteractions',
    countappointments: 'countappointments',
    counttransactions: 'counttransactions',
    // TODO: Ask lance - transactionvalue: 'transactionvalue',
    // TODO: Ask lance - transactioncurrency: 'transactioncurrency',
    // TODO: Ask lance - interactiondetails: 'interactiondetails',
    lifetimevalue: 'lifetimevalue',
    averagetransactionvalue: 'averagetransactionvalue',
    averagepurchasefrequency: 'averagepurchasefrequency',
    lastinteractiondetails: 'lastinteractiondetails',
    lastinteractiontimingdetails: 'lastinteractiontimingdetails',
  },
  // TODO: Add in message variables
  Message: {
    lastmessagedate: 'lastmessagedate',
    nextmessagedate: 'nextmessagedate',
  },
  Business: {
    companyName: 'companyname',
    companyDescription: 'companydescription',
  },
  TODO: {
    sentiment: 'sentiment',
    forwardingemailaddress: 'forwardingemailaddress',
    customersegmentdetails: 'customersegmentdetails',
    campaignPurpose: 'campaignPurpose',
    baseMessage: 'baseMessage',
  }
}

const AllCustomVariables = Object.values(CustomVariable).flatMap(variable => Object.keys(variable).map(value => value.toLowerCase()));
export const CustomVariableToDisplayTextMap : CustomVariableMap = Object.values(CustomVariable).flatMap(variable => Object.entries(variable)).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

/** Extracts variables from a prompt and returns a list of matches as well as a mapping to custom variables (lower cased). */
export function extractVariables(prompt: string) {
  const lowerCaseCustomVariables = new Set(AllCustomVariables);
  const matches = prompt.replaceAll('&nbsp;', ' ').match(/@([a-zA-Z]+)/g)?.filter((match) => lowerCaseCustomVariables.has(match.slice(1).toLowerCase())) ?? [];
  return matches.map((match) => ({ match, customVariable: match.slice(1).toLowerCase() }));
}

export function getDefaultSmsPrompt() {
  return `You are a campaign analyst tasked with personalising an existing base campaign message @BaseMessage so it meets the below brief: <br/>
    - Make the message suitable for the delivery channel: SMS <br/>
    - @CompanyDescription <br/>
    - Your message must feel personal, caring and not promotional in nature <br/>
    - Your message should be written in first person language, from the perspective of @SenderName on behalf of the company @CompanyName <br/>
    - You should address the message to @CustomerFirstName </br>
    - @CampaignPurpose </br>
    <br/>
    Your output must meet compliance requirements: <br/>
    - Being no longer than 800 characters <br/>
    - Do not include emojis </br>
    - Do not include an email title </br>
    - Do not indent paragraph breaks </br>
    - Line breaks should only be 1 line break maximum </br>
    - Remove any sensitive words </br>
    - Use Australian grammar </br>
    - Use normal capitalisation at start of sentences`
}

export function getPersonalizationText(selectedVariables: PersonalizationVariable[]) : string {
  let personalizationText = 'Personalise the base message and tone further based on two additional pieces of information: <br /> - You should address the message to @CustomerFirstName';
  if (selectedVariables.includes(PersonalizationVariable.CustomerSegment)) {
    personalizationText += '<br /> - The audience for this campaign are @CustomerSegment';
  }
  if (selectedVariables.includes(PersonalizationVariable.PurchaseHistory)) {
    personalizationText += '<br /> - This customers\' most recent purchase @RecentPurchase';
  }
  return personalizationText
}