import './StaticSection.scss';

import FormItem from '../../../engagements/forms/FormItem';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import { PromptSectionProps } from '../../SettingsConstants';

const STATIC_SECTION_TEXT_CAPTION = 'This message is static and will appear exactly like this in your campaigns.';

function StaticSection({ section, setSection }: PromptSectionProps<string>) {
  const { sectionText } = section;
  return (
    <section className={'static-section'}>
      <FormItem label={'Section text'}>
        <TextAreaInput text={sectionText} setText={setSection('sectionText')} placeholder={'Type your example message here'} />
        <span className={'static-section-caption'}>{STATIC_SECTION_TEXT_CAPTION}</span>
      </FormItem>
    </section>
  )
};

export default StaticSection;