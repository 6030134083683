import './PromptsTable.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';

import EllipsisDropdown from '../../common/dropdowns/EllipsisDropdown';
import PaginationBar from '../../common/tables/PaginationBar';
import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Table, { TableHeaderProps } from '../../common/tables/Table';
import { postDeletePrompt } from '../../../apis/SettingsApi';
import { User } from '../../../types/UserTypes';

const TEST_PROMPTS = [
  {
    _id: null,
    name: 'Test Prompt',
    message: 'Test Prompt Message',
    sections: []
  }
]

const PROMPTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Prompt name', key: 'name' },
  { displayText: 'Prompt message', key: 'message' },
  { displayText: 'Edit', key: 'edit' },
  { displayText: 'Send', key: 'send' },
  { displayText: 'More', key: 'more' },
];

const OFFERS_MAX_ROWS = 5;

function PromptsTable() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const [cursor, setCursor] = useState(0);
  // const [prompts, setPrompts] = useState<Prompt[]>(user && user.prompts ? user.prompts : TEST_PROMPTS);
  const prompts = user && user.prompts ? user.prompts : TEST_PROMPTS

  const rows = prompts.map(({ name, message }, index) => {
    const nameNode = <section className={'two-cell short-text'}>{name}</section>
    const messageNode = <section className={'two-cell short-text'}>{message}</section>

    // TODO: Archive, Duplicate, Publish
    const editPrompt = () => {
      navigate('/settings/prompts/createOrEdit', { state: { prompt: prompts[index] }});
    }
    const editNode = <OutlineButton content={'Edit'} additionalClassNames={['half-cell']} onClick={editPrompt}/>
    const sendNode = <PrimaryButton content={'Send'} additionalClassNames={['half-cell']} />

    const duplicatePrompt = () => {
      navigate('/settings/prompts/createOrEdit', { state: { prompt: { ...prompts[index], _id: null } }});
    };

    const deletePrompt = async () => {
      const updatedPrompts = await postDeletePrompt(prompts[index]._id);
      setUser({ ...user as User, prompts: updatedPrompts });
    };

    const moreOptions = [
      { displayText: 'Duplicate', value: index, onClick: duplicatePrompt },
      { displayText: 'Delete', value: index, onClick: deletePrompt },
    ]
    const moreNode = <EllipsisDropdown options={moreOptions} additionalClassNames={['half-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'message', value: messageNode },
        { key: 'edit', value: editNode },
        { key: 'send', value: sendNode },
        { key: 'more', value: moreNode },
      ]
    }
  });
  
  return (
    <>
      <section className={'prompts-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['prompts-table']}
            headers={PROMPTS_TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={OFFERS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={OFFERS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
      </section>
    </>
  );
}

export default PromptsTable;
