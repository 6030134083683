import './HoverIconButton.scss';

import { useState, MouseEvent } from 'react';

import GhostButton from '../GhostButton';

interface HoverIconButtonProps {
  onClick?: ((event: MouseEvent) => void),
  disabled?: boolean,
  icon: string,
  hoveredIcon: string,
  onMouseEnter?: ((event: MouseEvent) => void),
  onMouseLeave?: ((event: MouseEvent) => void),
  additionalClassNames?: string[],
}

function HoverIconButton({ onClick, disabled, icon, hoveredIcon, onMouseEnter, onMouseLeave, additionalClassNames = [] } : Readonly<HoverIconButtonProps>) {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = (event: MouseEvent) => {
    setHovered(true);
    if (onMouseEnter) {
      onMouseEnter(event);
    }
  }
  const handleMouseLeave = (event: MouseEvent) => {
    setHovered(false);
    if (onMouseLeave) {
      onMouseLeave(event);
    }
  }
  const content = hovered
    ? <img src={hoveredIcon} alt={''} />
    : <img src={icon} alt={''} />;
  return <GhostButton
    additionalClassNames={['hover-icon-button', ...additionalClassNames]}
    onClick={onClick}
    content={content}
    disabled={disabled}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  />
}

export default HoverIconButton