import './PreviewMessage.scss';

import { useState, useRef, useEffect } from 'react';

import PreviewHeader from './common/PreviewHeader';
import PreviewFooter from './common/PreviewFooter';
import PaginationBar from '../../common/tables/PaginationBar';
import { Channel, VariantMessage } from '../EngagementsTypes';
import SmsPreview from './SmsPreview';

const MAX_ROWS_PER_PAGE = 1;

function isSms(channel: Channel | null): boolean {
  return channel === Channel.SMS;
}

interface IPreviewMessage extends VariantMessage {
  channel: Channel | null,
  variantIndex: number,
  messageIndex: number,
}

interface PreviewMessageProps {
  previews: IPreviewMessage[],
  setPreview: (message: string, messageIndex: number) => void,
  totalMessages: number,
  totalRecipients: number,
}

function PreviewMessage({ previews, setPreview, totalMessages, totalRecipients } : PreviewMessageProps) {
  const [editing, setEditing] = useState(false);
  const [cursor, setCursor] = useState(0);
  const messageNumber = cursor + 1;
  const { channel, recipient, message, messageIndex } = previews[cursor];
  const { firstName, lastName, emailAddress, mobileNumber } = recipient;
  const isSmsChannel = isSms(channel);
  const name = `${firstName} ${lastName}`;
  const target = isSmsChannel ? `${mobileNumber} (Mobile)`: `${emailAddress} (Email)`;
  // TODO: Update to use actual scheduling date logic
  const schedule = '';
  // const schedule = 'Sept 10, 2024, 8:00am';
  const contentRef = useRef(message);

  // TODO: Add back in once email is added
  // const titleRef = useRef(title);
  const save = () => {
    setPreview(contentRef.current, messageIndex);
  };

  useEffect(() => {
    const { message } = previews[cursor];
    contentRef.current = message;
  }, [previews, cursor]);

  /**  TODO: Add back in email channel
  const message = isSmsChannel
    ? <SmsPreview content={content} editing={editing} contentRef={contentRef} />
    : <EmailPreview title={title ? title : ''} editing={editing} content={content} titleRef={titleRef} contentRef={contentRef} />
  */
  const messageDisplay = <SmsPreview content={message} editing={editing} contentRef={contentRef} />
  return (
    <section className={'preview-message-container'}>
      <section className={'preview-message'}>
        <PreviewHeader name={name} target={target} schedule={schedule} messageNumber={messageNumber} totalMessages={totalRecipients} />
        <section className={'message-container'}>
          {messageDisplay}
        </section>
        <PreviewFooter editing={editing} setEditing={setEditing} save={save} />
      </section>
      <PaginationBar cursor={cursor} setCursor={setCursor} maxRowsPerPage={MAX_ROWS_PER_PAGE} totalRows={totalMessages} />
    </section>
  );
};

export default PreviewMessage;