import './PromptPreviewPage.scss';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import RecipientSearchBar from '../../engagements/modals/add-recipients/RecipientSearchBar';
import AIPromptSection from '../../engagements/forms/AIPromptSection';
import PreviewMessage from '../../engagements/previews/PreviewMessage';
import AIPromptModal from '../../engagements/modals/AIPromptModal';
import useAuth from '../../../context/AuthContext';
import { getEmptyEngagement } from '../../engagements/helpers/EngagementHelpers';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import { Engagement, Variant, EngagementStatus } from '../../engagements/EngagementsTypes';
// import ConfirmEngagementWithoutPreviewingModal from '../modals/ConfirmEngagementWithoutPreviewingModal';
import SubtleButton from '../../common/buttons/SubtleButton';
// import GeneratingMessagesModal from '../modals/GeneratingMessagesModal';
import { postPublishEngagement, postGeneratePreviewsForEngagement } from '../../../apis/EngagementApi';
import { User } from '../../../types/UserTypes';
import { PersonalizationVariable } from '../../engagements/helpers/AIPromptHelpers';
import { postGeneratePreviews } from '../../../apis/EngagementApi';
import CreateEngagementModal from '../../engagements/modals/CreateEngagementModal';
import { postSaveOrUpdatePrompt } from '../../../apis/SettingsApi';

const { CreateEngagementKey } = LocalStorageKey;

const { Review } = EngagementStatus;

const MAX_NUM_PREVIEWS = 5;

function PromptPreviewPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { prompt } = state;
  const { previews = [] } = prompt;
  const { user, setUser } = useAuth();
  const [showingConfirmEngagementModal, setShowingConfirmEngagementModal] = useState(false);
  // const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  // const [engagement, setEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  

  const isEditingExistingPrompt = state && state.prompt && state.prompt._id;
  const [aiModalOpen, setAIModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loadingGenerateAllPreviewsButton, setLoadingGenerateAllPreviewsButton] = useState(false);
  const [showingGeneratingMessagesModal, setShowingGeneratingMessagesModal] = useState(false);
  const [showingConfirmWithoutPreviewingModal, setShowingConfirmWithoutPreviewingModal] = useState(false);
  const back = () => navigate('/settings/prompts/createOrEdit', { state: { prompt } });
  const finish = () => navigate('/settings/prompts');
  const previewMessages = previews ? previews.map((message: any, messageIndex: number) => ({ ...message, channel: 'SMS', messageIndex })) : [];
  const numberOfRecipients = 5;
  const savePrompt = async () => {
    console.log(prompt);
    const response = await postSaveOrUpdatePrompt(prompt);
    console.log(response);
    setUser({ ...user as User, prompts: response.prompts });

    finish();
  };
  const buttonText = isEditingExistingPrompt ? 'Save Prompt' : 'Create Prompt';
  const numberOfMessages = previewMessages.length;
  const setMessage = (message: string, messageIndex: number) => {
    const newMessages = prompt.previews;
    const newMessage = newMessages[messageIndex];
    newMessages[messageIndex] = { ...newMessage, message };
  }

  const openAIModal = () => setAIModalOpen(true);
  const closeAIModal = () => setAIModalOpen(false);
  return (
    <article className={'prompt-preview-page'}>
      <section className={'prompt-preview-page-content'}>
        <header className={'prompt-preview-page-header'}>
          <h2 className={'title'}>{'Preview your messages'}</h2>
          <section className={'subtitle-container'}>
            <section className={'subtitle-and-search'}>
              <h3 className={'subtitle'}>
                <span className={'subtitle-text'}>
                  {'There are '}
                  <b>{numberOfRecipients}</b>
                  {' preview messages for you to review. These are generated from random data to give you a examples of what your message outputs may look like.'}
                </span>
              </h3>
            </section>
            {/* <AIPromptSection openAIModal={openAIModal} /> */}
          </section>
        </header>
        { previewMessages.length > 0 &&
          <PreviewMessage
            previews={previewMessages}
            setPreview={setMessage}
            totalMessages={numberOfMessages}
            totalRecipients={numberOfRecipients}
          />
    }
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          {/* <SubtleButton content={generatePreviewsText} onClick={generateAllPreviews} loading={loadingGenerateAllPreviewsButton} additionalClassNames={[isReview ? 'review' : '']} /> */}
          <PrimaryButton content={buttonText} onClick={savePrompt} />
        </section>
        <section className={'footer-right'}>{'2/2 steps'}</section>
      </footer>
    </article>
  );
}

export default PromptPreviewPage;