import './RecipientsPage.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';

import SearchInput from '../../../common/inputs/SearchInput';
import RecipientsTable from '../tables/RecipientsTable';
import RecipientFilter from '../common/RecipientFilter';
import BulkImportButtonBar from './BulkImportButtonBar';
import { Deliverability, DeliverabilityPreferences, Status } from '../../PeopleConstants';
import LoadingRingPage from '../../../common/pages/LoadingRingPage';
import { Filter } from '../../../common/multi-select-rows/filters/FilterConstants';
import { RecipientRow } from '../../../engagements/modals/add-recipients/RecipientSelectionTable';

const { SMS, EMAIL } = DeliverabilityPreferences;

const TEST_RECIPIENTS = [
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'Andrew',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
  {
    _id: '123',
    clientId: '123',
    firstName: 'bob',
    lastName: 'Zhang',
    fullName: 'Andrew Zhang',
    emailAddress: 'andrew@locum.ly',
    mobileNumber: '0434729991',
    deliverability: Deliverability.SUBSCRIBED,
    preferences: [SMS, EMAIL],
    addedDate: Date.now(),
    status: Status.ACTIVE,
    address: '1503/25 Wills Street',
    source: 'Referral',
    dateOfBirth: Date.now(),
    creationDate: Date.now(),
    createdBy: null,
    businessId: null,
    deletionDate: null,
    alreadySelected: false,
    groupId: null
  },
]

const INITIAL_PAGE_LOAD = 5;

function RecipientsPage() {
  const [page, setPage] = useState(0);
  const resetPage = () => setPage(0);

  const [text, setText] = useState('');
  const [filters, setFilters] = useState<Filter[]>([]);
  const { user } = useAuth();
  const userRecipients = user && user.recipients ? user.recipients : [];
  const [recipients, setRecipients] = useState<RecipientRow[]>(userRecipients);
  const [filteredRecipients, setFilteredRecipients] = useState<RecipientRow[]>(recipients);

  const searchRecipients = (searchText: string) => {
    setText(searchText);
    resetPage();
  }

  const filterRecipients = (recipients: RecipientRow[]) => {
    setFilteredRecipients(recipients);
    resetPage();
  }

  if (recipients === null) {
    <LoadingRingPage color={'black'} />
  }
  return (
    <article className={'recipients-page'}>
      <header className={'recipients-page-header'}>
        <h2 className={'title'}>{'Recipients'}</h2>
        <h3 className={'subtitle'}>{'View, add and message your recipients'}</h3>
        <SearchInput
          placeholder={'Type to search'}
          text={text}
          setText={searchRecipients}
        />
        <RecipientFilter filteredRecipients={filteredRecipients} recipients={recipients} setFilteredRecipients={filterRecipients} filters={filters} setFilters={setFilters} />
        <BulkImportButtonBar setRecipients={setRecipients} />
      </header>
      <section className={'recipients-page-content'}>
        <RecipientsTable
          recipients={filteredRecipients}
          setFilteredRecipients={filterRecipients}
          page={page}
          setPage={setPage}
          searchText={text}
        />
      </section>
    </article>
  );
}

export default RecipientsPage;