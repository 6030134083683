import './PromptsPage.scss';

import { useNavigate } from 'react-router';

import PromptsTable from '../tables/PromptsTable';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';

function PromptsPage() {
  const navigate = useNavigate();

  const createPrompt = () => {
    navigate('/settings/prompts/createOrEdit');
  }

  return (
    <article className={'prompts-page'}>
      <header className={'prompts-page-header'}>
        <h2 className={'title'}>{'Message prompts'}</h2>
        <h3 className={'subtitle'}>
          {'Add, edit, and view all your saved message prompts'}
        </h3>
        <PrimaryPlusButton content={'Add new prompt'} additionalClassNames={['create-prompt-button']} onClick={createPrompt} />
      </header>
      <section className={'prompts-page-content'}>
        <PromptsTable />
      </section>
    </article>
  );
}

export default PromptsPage;