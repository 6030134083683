import './CreateOrEditPromptForm.scss';

import { Prompt, PromptSection } from '../SettingsConstants';
import FormItem from '../../engagements/forms/FormItem';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import PromptSectionManager from '../common/prompts/PromptSectionManager';

const NEW_SECTION = {
  order: null,
  type: null,
  sectionText: ''
};

interface CreateOrEditPromptFormProps {
  isEditingExistingPrompt: boolean,
  prompt: Prompt,
  setPrompt: (engagement: Prompt) => void,
}

function CreateOrEditPromptForm({ isEditingExistingPrompt, prompt, setPrompt }: Readonly<CreateOrEditPromptFormProps>) {
  const {
    name,
    sections
  } = prompt;

  const setPromptName = (promptName: string) => setPrompt({ ...prompt, name: promptName });
  const addSection = () => setPrompt({ ...prompt, sections: [ ...sections, { ...NEW_SECTION, order: sections.length + 1 } ]})
  const setSection = (section: PromptSection) => {
    const { order } = section;
    const updatedSections = [ ...sections ];
    updatedSections[order - 1] = section;
    setPrompt({ ...prompt, sections: updatedSections });
  };

  const sectionNodes = sections.map((section) => {
    const deleteSection = () => {
      const filteredSections = [ ...sections ]
        .filter(filteredSection => filteredSection.order !== section.order)
        .map((updatedSection, index) => ({ ...updatedSection, order: index + 1 }));
      setPrompt({ ...prompt, sections: filteredSections });
    };

    const moveSectionUp = (moveUp: boolean) => () => {
      const sectionsToReorder = [ ...sections ];
      const sectionOrder = section.order;
      if (moveUp && sectionOrder > 1) {
        const currentOrderIndex = sectionOrder - 1;
        const newOrderIndex = sectionOrder - 2;
        sectionsToReorder[newOrderIndex].order = currentOrderIndex + 1;
        sectionsToReorder[currentOrderIndex].order = newOrderIndex + 1;
        const reorderedSections = sectionsToReorder.sort((a, b) => a.order - b.order);
        setPrompt({ ...prompt, sections: reorderedSections });
      }
      else if (!moveUp && sectionOrder < sections.length) {
        const currentOrderIndex = sectionOrder - 1;
        const newOrderIndex = sectionOrder;
        sectionsToReorder[newOrderIndex].order = currentOrderIndex + 1;
        sectionsToReorder[currentOrderIndex].order = newOrderIndex + 1;
        const reorderedSections = sectionsToReorder.sort((a, b) => a.order - b.order);
        setPrompt({ ...prompt, sections: reorderedSections });
      }
    }

    return (
      <PromptSectionManager
        key={section.order}
        section={section}
        setSection={setSection}
        deleteSection={deleteSection}
        moveSectionUp={moveSectionUp}
      />
    );
  });

  return (
    <section className={'create-edit-prompt-form'}>
      <section className={'create-edit-prompt-form-content'}>
        <FormItem label={'Prompt name'}>
          <PrimaryInput placeholder={'Add name'} setText={setPromptName} text={name} disabled={isEditingExistingPrompt} />
        </FormItem>
        {sectionNodes}
      </section>
      <PrimaryPlusButton content={'Add section'} onClick={addSection} />
    </section>
  );
}

export default CreateOrEditPromptForm;